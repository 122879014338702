import i18n from "i18next";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import acoesReducer from "./reducers/acoesReducer";
import analiseReducer from "./reducers/analiseReducer";
import regionReducer from "./reducers/regionReducer";
import cadastramentoReducer from "./reducers/cadastramentoReducer";
import choiceReducer from "./reducers/choiceReducer";
import glossarioReducer from "./reducers/glossarioReducer";
import layerReducer from "./reducers/layerReducer";
import legalidadeReducer from "./reducers/legalidadeReducer";
import legendBoxesReducer from "./reducers/legendBoxesReducer";
import mapReducer from "./reducers/mapReducer";
import municipalitiesReducer from "./reducers/municipalitiesReducer";
import sendMailReducer from "./reducers/sendMailReducer";
import sobreposicoesReducer from "./reducers/sobreposicoesReducer";
import sobreReducer from "./reducers/sobreReducer";
import statesReducer from "./reducers/statesReducer";
import toastReducer from "./reducers/toast.Reducer";
import transparenciaReducer from "./reducers/transparenciaReducer";
import praReducer from "./reducers/praReducer";
import zeeReducer from "./reducers/zeeReducer";
import linksAppReducer from "./reducers/linksAppReducer";
import methodologyReducer from "./reducers/methodologyReducer";
import statisticsReducer from "./reducers/statisticsReducer";
import barWithDataReducer from "./reducers/barWithData.Reducer";
import filterUfReducer from "./reducers/filterUfReducer";
import quilombosBrasilReducer from "./reducers/quilombosBrasilReducer";
import biomesBrasilReducer from "./reducers/biomesBrasilReducer";
import settlementsReducer from "./reducers/settlementsReducer";
import embargoesReducer from "./reducers/embargoesReducer";
import suppressionReducer from "./reducers/suppressionReducer";

const reducer = combineReducers({
  toast: toastReducer,
  sendMail: sendMailReducer,
  states: statesReducer,
  municipalities: municipalitiesReducer,
  linksApp: linksAppReducer,
  choice: choiceReducer,
  analise: analiseReducer,
  transparencia: transparenciaReducer,
  pra: praReducer,
  cadastramento: cadastramentoReducer,
  legalidade: legalidadeReducer,
  zee: zeeReducer,
  acoes: acoesReducer,
  sobreposicoes: sobreposicoesReducer,
  sobre: sobreReducer,
  glossario: glossarioReducer,
  legendBoxes: legendBoxesReducer,
  layers: layerReducer,
  map: mapReducer,
  regionInfo: regionReducer,
  methodology: methodologyReducer,
  statistics: statisticsReducer,
  barWithData: barWithDataReducer,
  filterUf: filterUfReducer,
  quilombosBrasil: quilombosBrasilReducer,
  biomes: biomesBrasilReducer,
  settlements: settlementsReducer,
  embargo: embargoesReducer,
  suppression: suppressionReducer,
});

const initialState = {
  states: { statesInner: [] },
  municipalities: { municipalitiesInner: [] },
  choice: {
    active: 0,
    name: i18n.t("settlements"),
    type: "settlements",
    territorialCut: { label: i18n.t("brazil").toUpperCase(), value: "brazil" },
  },
  analise: {},
  methodology: {},
  toast: {},
  transparencia: {},
  pra: {},
  cadastramento: {},
  legalidade: {},
  zee: {},
  acoes: {},
  sobreposicoes: {},
  sobre: { sobreInner: [] },
  glossario: { glossarioInner: [] },
  legendBoxes: { legendBoxesInner: [] },
  linksApp: {},
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
